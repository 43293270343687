.linked-animate-demo-wrapper {
    overflow: hidden;
    height: 500px;
    background: rgb(2, 0, 36);
    background: linear-gradient(
        27deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(9, 9, 121, 1) 35%,
        rgba(0, 212, 255, 1) 100%
    );

    position: relative;
    opacity: 0.6;
}

.linked-animate-demo-box {
    position: absolute;
    width: 1280px;
    height: 600px;
    display: block;
    left: -100%;
    top: 0;
    bottom: 0;
    right: -100%;
    margin: auto;
}

.linked-animate-demo-block {
    position: absolute;
    transition: transform 0.45s ease;
}

.linked-animate-demo-block-child {
    border-radius: 100%;
    width: 100%;
    height: 100%;
}
